import { divisionMappings, mapMappings } from "../../Constants";
import { Match } from "../../models/Match";
import './../../styles/theme.css';
import { Link } from "react-router-dom";
import useLocalStorage from 'use-local-storage';

interface MatchDetailProps {
    matches: Match[];
}

const MatchDetail: React.FC<MatchDetailProps> = ({ matches }) => {
    const [isDark] = useLocalStorage<boolean>("isDark", false);

    let team1Wins = 0;
    let team2Wins = 0;
    const team1 = matches[0].team1;
    const team2 = matches[0].team2;

    if (matches.length > 1) {
        matches.forEach((match) => {
            if (match.team1score > match.team2score) {
                team1Wins++;
            } else {
                team2Wins++;
            }
        });
    } else {
        team1Wins = matches[0].team1score;
        team2Wins = matches[0].team2score;
    }

    return (
        <div className={`text-center ${isDark ? 'bg-dark text-light' : ''}`}>
            <div className="row">
                {/* Team 1 Card */}
                <div className="col-5">
                    <div
                        className={`card mb-3 ${isDark ? 'text-light' : ''}`}
                        style={
                            isDark
                                ? {
                                    border: '1px solid #111',
                                    backgroundColor: '#343a40', // Lighter dark color
                                }
                                : undefined
                        }
                    >
                        <div className="card-body">
                            <Link
                                to={`/team/${team1.teamId}`}
                                className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'}`}
                            >
                                <img src={team1.teamLogo} alt="TeamLogo" style={{ width: 100 }} />
                                <h5 className="card-title">{team1.teamName}</h5>
                            </Link>
                            <h1 className={matches[0].team1won ? "text-success" : "text-danger"}>{team1Wins}</h1>
                        </div>
                    </div>
                </div>

                {/* VS and Match Info */}
                <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                    <h1 className="text-center">VS</h1>
                    <h6>{new Date(matches[0].match_time).toLocaleDateString('da-DK').replaceAll(".", "/")}</h6>
                    {
                        matches.length === 1 && (
                            <>
                                <h6>{mapMappings.get(matches[0].mapName)}</h6>
                                <h6>{divisionMappings.get(matches[0].division)}</h6>
                            </>
                        )
                    }
                    <h6>Sæson {matches[0].season}</h6>
                </div>

                {/* Team 2 Card */}
                <div className="col-5">
                    <div
                        className={`card mb-3 ${isDark ? 'text-light' : ''}`}
                        style={
                            isDark
                                ? {
                                    border: '1px solid #111',
                                    backgroundColor: '#343a40', // Lighter dark color
                                }
                                : undefined
                        }
                    >
                        <div className="card-body">
                            <Link
                                to={`/team/${team2.teamId}`}
                                className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'}`}
                            >
                                <img src={team2.teamLogo} alt="TeamLogo" style={{ width: 100 }} />
                                <h5 className="card-title">{team2.teamName}</h5>
                            </Link>
                            <h1 className={!matches[0].team1won ? "text-success" : "text-danger"}>{team2Wins}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default MatchDetail;
