import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import Filters from './Filters';
import Loading from '../../components/Loading';
import { simpleDivisionMappings, roles, divisionMappings } from '../../Constants';
import { isTeamOwner, fetchTeamsByOwner } from '../../services/TeamService';
import { fetchLookingForPlayersPosts, createLookingForPlayersPost, updateLookingForPlayersPost, deleteLookingForPlayersPost } from '../../services/LookingForPlayersService';
import { LookingForPlayers } from '../../models/LookingForPlayers';
import { Team } from '../../models/Team';
import styles from './LookingForPlayersCSS.module.css'; // Import the CSS module
import PostCardSection from './PostCardSection';
import PostCardRequirement from './PostCardRequirement';

const LookingForPlayersPage: React.FC = () => {
    const [posts, setPosts] = useState<LookingForPlayers[]>([]);
    const [filteredPosts, setFilteredPosts] = useState<LookingForPlayers[]>([]);
    const [loading, setLoading] = useState(true);
    const [ownedTeams, setOwnedTeams] = useState<Team[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [editingPost, setEditingPost] = useState<LookingForPlayers | null>(null);
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
    const [newPost, setNewPost] = useState({
        title: "",
        minELO: 1500,
        minDivisionRequirements: "",
        roleRequirements: [] as string[],
        requirements: "",
        whatWeOffer: ""
    });
    const [isPhone] = useState(window.innerWidth < 768);
    const { user } = useContext(AuthContext);

    const fetchPosts = useCallback(async () => {
        setLoading(true);
        try {
            const postsData = await fetchLookingForPlayersPosts();
            setPosts(postsData);
            setFilteredPosts(postsData);
        } catch (error) {
            console.error("Error fetching posts:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleTeamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const teamId = e.target.value;
        const team = ownedTeams.find(t => t.teamId === teamId);
        setSelectedTeam(team || null);

        if (team) {
            const existingPost = posts.find(post => post.teamId === team.teamId);
            if (existingPost) {
                setEditingPost(existingPost);
                setNewPost({
                    title: existingPost.title,
                    minELO: existingPost.minELO,
                    minDivisionRequirements: existingPost.minDivisionRequirements,
                    roleRequirements: existingPost.roleRequirements,
                    requirements: existingPost.requirements,
                    whatWeOffer: existingPost.whatWeOffer,
                });
            } else {
                setEditingPost(null);
                resetNewPost();
            }
        }
    };

    const fetchOwnedTeams = useCallback(async () => {
        if (user) {
            try {
                const teams = await fetchTeamsByOwner(user.steamID);
                const uniqueTeams = teams.filter((team, index, self) =>
                    index === self.findIndex(t => t.teamId === team.teamId)
                );
                setOwnedTeams(uniqueTeams);

                // Auto-select the first team if only one is available
                if (teams.length === 1) {
                    setSelectedTeam(uniqueTeams[0]);
                }
            } catch (error) {
                console.error("Error fetching owned teams:", error);
            }
        }
    }, [user]);

    useEffect(() => {
        fetchPosts();
        fetchOwnedTeams();
    }, [fetchPosts, fetchOwnedTeams]);

    const handleCreatePost = async () => {
        if (!user) return;

        if (ownedTeams.length === 0) {
            // If the user is not a team owner, open the link instead of showing the modal
            window.open('https://x.com/realxGuys', '_blank');
            return;
        }

        if (!selectedTeam) {
            alert("Vælg venligst et hold, før du opretter et opslag.");
            return;
        }

        const existingPost = posts.find(post => post.teamId === selectedTeam.teamId);
        if (existingPost) {
            alert("Dette hold har allerede et opslag. Du kan ikke oprette flere opslag for det samme hold, rediger venligst det eksisterende opslag i stedet.");
            return;
        }

        try {
            const isOwner = await isTeamOwner(user.steamID);
            if (!isOwner) {
                alert("Du er ikke holdets ejer. Kontakt os venligst på Discord eller Casper på https://x.com/realxGuys.");
                return;
            }
        } catch (error) {
            console.error("Error checking if user is owner:", error);
        }

        setEditingPost(null);
        resetNewPost();
        setShowModal(true);
    };

    const handleEditPost = async () => {
        if (user && editingPost) {
            const updatedPostData: LookingForPlayers = {
                ...editingPost,
                title: newPost.title,
                minELO: newPost.minELO,
                minDivisionRequirements: newPost.minDivisionRequirements,
                roleRequirements: newPost.roleRequirements,
                requirements: newPost.requirements,
                whatWeOffer: newPost.whatWeOffer,
            };

            try {
                const updatedPost = await updateLookingForPlayersPost(updatedPostData);
                const updatedPosts = posts.map(post => post.id === updatedPost.id ? updatedPost : post);
                setPosts(updatedPosts);
                setFilteredPosts(updatedPosts);
                setShowModal(false);
                setEditingPost(null);
                resetNewPost(); // Reset form after editing
            } catch (error) {
                console.error("Error updating post:", error);
            }
        }
    };

    const handleSavePost = async () => {
        if (user && selectedTeam) {
            const newPostData: LookingForPlayers = {
                id: 0, // id will be set by the backend upon creation
                title: newPost.title,
                minELO: newPost.minELO,
                minDivisionRequirements: newPost.minDivisionRequirements,
                roleRequirements: newPost.roleRequirements,
                requirements: newPost.requirements,
                whatWeOffer: newPost.whatWeOffer,
                teamId: selectedTeam.teamId,
                teamLogo: selectedTeam.teamLogo,
                teamName: selectedTeam.teamName, // Include teamName
                lastDivision: selectedTeam.division,
                createdAt: new Date()
            };

            try {
                const createdPost = await createLookingForPlayersPost(newPostData);
                setPosts([...posts, createdPost]);
                setFilteredPosts([...posts, createdPost]);
                setShowModal(false);
                resetNewPost(); // Reset form after creating
            } catch (error) {
                console.error("Error creating post:", error);
            }
        }
    };

    const handleDeletePost = async (postId: number) => {
        const confirmed = window.confirm("Er du sikker på, at du vil slette dette opslag?");
        if (confirmed) {
            try {
                const success = await deleteLookingForPlayersPost(postId);
                if (success) {
                    const updatedPosts = posts.filter(post => post.id !== postId);
                    setPosts(updatedPosts);
                    setFilteredPosts(updatedPosts);
                } else {
                    alert("Failed to delete the post. Please try again.");
                }
            } catch (error) {
                console.error("Error deleting post:", error);
                alert("An error occurred while deleting the post. Please try again.");
            }
        }
    };

    const resetNewPost = () => {
        setNewPost({
            title: "",
            minELO: 1500,
            minDivisionRequirements: "",
            roleRequirements: [],
            requirements: "",
            whatWeOffer: ""
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setNewPost({ ...newPost, [name]: value });
    };

    const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        setNewPost(prevState => {
            const newRoleRequirements = checked
                ? [...prevState.roleRequirements, value]
                : prevState.roleRequirements.filter(role => role !== value);
            return { ...prevState, roleRequirements: newRoleRequirements };
        });
    };

    const renderPostCards = () => {
        if (loading) {
            return (
                <div className="text-center">
                    <Loading />
                </div>
            );
        } else if (filteredPosts.length === 0) {
            return (
                <div className="text-center">
                    <p>Der er ingen opslag tilgængelige.</p>
                </div>
            );
        }

        return (
            <div className="d-flex flex-wrap justify-content-center">
                {filteredPosts.map((post) => {
                    const requirements = [
                        {
                            title: 'Minimum Division',
                            content: simpleDivisionMappings.get(post.minDivisionRequirements) || 'N/A',
                        },
                        {
                            title: 'Min ELO',
                            content: post.minELO.toString() || 'N/A',
                        },
                        {
                            title: 'Role Requirements',
                            content: post.roleRequirements.join(", ") || 'N/A',
                        },
                    ];

                    const userOwnsTeam = ownedTeams.some(team => team.teamId === post.teamId);

                    return (
                        <div className={`card m-3 ${styles.card}`} key={post.id}>
                            <div className={`card-header ${styles.cardHeader}`}>
                                <div className='row'>
                                    <div className="col-12 col-md-8">
                                        <h5 className="text-wrap" style={{ wordBreak: 'break-word' }}>{post.title}</h5>
                                    </div>
                                    {!isPhone && (
                                        <div className='col'>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <img
                                                    src={post.teamLogo}
                                                    alt="Team Logo"
                                                    className={styles.teamLogo}
                                                    style={{ width: '50px', height: '50px', marginRight: '10px' }}
                                                />
                                                <div>
                                                    <h5 className="card-title m-0">{post.teamName}</h5>
                                                    <h6 className="card-subtitle mb-2 text-muted m-0">{divisionMappings.get(post.lastDivision)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {isPhone && (
                                    <div className="d-flex justify-content-start align-items-center">
                                        <img
                                            src={post.teamLogo}
                                            alt="Team Logo"
                                            className={styles.teamLogo}
                                            style={{ width: '50px', height: '50px', marginRight: '10px' }}
                                        />
                                        <div>
                                            <h5 className="card-title m-0">{post.teamName}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted m-0">{divisionMappings.get(post.lastDivision)}</h6>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="card-body d-flex flex-column flex-md-row justify-content-between align-items-stretch">
                                <div className={`flex-grow-1 pr-2 border-right ${styles.cardBodySection}`}>
                                    <PostCardRequirement requirements={requirements} />
                                </div>
                                <div className='mt-3 mt-md-0'>
                                    <PostCardSection
                                        title="What We Offer"
                                        content={post.whatWeOffer || 'N/A'}
                                    />
                                    <PostCardSection
                                        title="Additional Requirements"
                                        content={post.requirements || 'N/A'}
                                    />
                                </div>
                            </div>
                            <div className="card-footer text-center">
                                <Link to={`/team/${post.teamId}`} className="btn btn-primary m-1">
                                    Se Team
                                </Link>
                                {userOwnsTeam && (
                                    <>
                                        <button
                                            className="btn btn-secondary m-1"
                                            onClick={() => {
                                                setEditingPost(post);
                                                setNewPost({
                                                    title: post.title,
                                                    minELO: post.minELO,
                                                    minDivisionRequirements: post.minDivisionRequirements,
                                                    roleRequirements: post.roleRequirements,
                                                    requirements: post.requirements,
                                                    whatWeOffer: post.whatWeOffer,
                                                });
                                                setShowModal(true);
                                            }}
                                        >
                                            Rediger Opslag
                                        </button>
                                        <button
                                            className="btn btn-danger m-1"
                                            onClick={() => handleDeletePost(post.id)}
                                        >
                                            Slet Opslag
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderCreatePostSection = () => {
        if (ownedTeams.length === 0) {
            return (
                <div className="text-center">
                    <p>Du er sat som holdejer på et hold, og kan derfor ikke søge spillere, kontakt os hvis du er en holdejer.</p>
                    <button className="btn btn-primary mt-3" onClick={handleCreatePost}>
                        Kontakt Os
                    </button>
                </div>
            );
        }

        return (
            <div className="text-center">
                <button className="btn btn-primary mt-3" onClick={handleCreatePost}>
                    Lav Opslag
                </button>
            </div>
        );
    };

    return (
        <div>
            <div className="text-center">
                <h1>Looking for Players</h1>
                <p>Her kan du finde hold, der søger spillere.</p>

                {renderCreatePostSection()}



                {ownedTeams.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <div>
                            <label htmlFor="teamSelect" style={{ display: 'block', textAlign: 'center', marginBottom: '5px' }}>
                                Vælg et hold:
                            </label>
                            <select
                                id="teamSelect"
                                className="form-select"
                                onChange={handleTeamChange}
                                style={{
                                    width: '250px',
                                    padding: '5px',
                                    fontSize: '16px',
                                    textAlign: 'center'
                                }}
                            >
                                <option value="">Vælg dit hold</option>
                                {ownedTeams.map((team) => (
                                    <option key={team.teamId} value={team.teamId}>
                                        {team.teamName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
            </div>
            <hr />
            <div className="row">
                <div className="col-3">
                    <Filters posts={posts} setFilteredPosts={setFilteredPosts} />
                </div>
                <div className="col-9">
                    {renderPostCards()}
                </div>
            </div>

            {showModal && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContainer}>
                        <div className={styles.modalHeader}>
                            <h2>{editingPost ? "Rediger opslag" : "Lav et nyt opslag"}</h2>
                            <button className={styles.modalCloseButton} onClick={() => setShowModal(false)}>X</button>
                        </div>
                        <div className={styles.modalBody}>
                            <form>
                                <div className={styles.formGroup}>
                                    <label htmlFor="postTitle">Title</label>
                                    <input
                                        type="text"
                                        id="postTitle"
                                        name="title"
                                        value={newPost.title}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className={styles.formGroup}>
                                    <label htmlFor="postMinELO">Minimum ELO</label>
                                    <input
                                        type="number"
                                        id="postMinELO"
                                        name="minELO"
                                        value={newPost.minELO}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className={styles.formGroup}>
                                    <label htmlFor="postDivision">Division Requirements</label>
                                    <select
                                        id="postDivision"
                                        name="minDivisionRequirements"
                                        value={newPost.minDivisionRequirements}
                                        onChange={handleInputChange}
                                    >
                                        {Array.from(simpleDivisionMappings.entries()).map(([key, value]) => (
                                            <option key={key} value={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={styles.formGroup}>
                                    <label>Role Requirements</label>
                                    {roles.map((role) => (
                                        <div key={role}>
                                            <input
                                                type="checkbox"
                                                value={role}
                                                checked={newPost.roleRequirements.includes(role)}
                                                onChange={handleRoleChange}
                                            />
                                            <label>{role}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.formGroup}>
                                    <label htmlFor="postRequirements">Requirements</label>
                                    <textarea
                                        id="postRequirements"
                                        name="requirements"
                                        value={newPost.requirements}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className={styles.formGroup}>
                                    <label htmlFor="postWhatWeOffer">What We Offer</label>
                                    <textarea
                                        id="postWhatWeOffer"
                                        name="whatWeOffer"
                                        value={newPost.whatWeOffer}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className={styles.modalFooter}>
                            <button className={styles.modalCloseButton} onClick={() => setShowModal(false)}>Close</button>
                            <button className={styles.modalSaveButton} onClick={editingPost ? handleEditPost : handleSavePost}>
                                {editingPost ? "Gem Ændringer" : "Gem Opslag"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LookingForPlayersPage;
