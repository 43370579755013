export const baseURL = process.env.REACT_APP_API_URL + "/api";

export const divisionMappings: Map<string, string> = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2divA", "2 Division A"],
    ["2divB", "2 Division B"],
    ["3divA", "3 Division A"],
    ["3divB", "3 Division B"],
    ["3divC", "3 Division C"],
    ["3divD", "3 Division D"],
    ["4divA", "4 Division A"],
    ["4divB", "4 Division B"],
    ["4divC", "4 Division C"],
    ["4divD", "4 Division D"],
    ["5divA", "5 Division A"],
    ["5divB", "5 Division B"]
]);

export const divisionMappingsSimple: Map<string, string> = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2div", "2 Division"],
    ["3div", "3 Division"],
    ["4div", "4 Division"],
    ["5div", "5 Division"],
]);

export const simpleDivisionMappings: Map<string, string> = new Map([
    ["1", "Ligaen"],
    ["2", "1 Division"],
    ["3", "2 Division"],
    ["4", "3 Division"],
    ["5", "4 Division"],
    ["6", "5 Division"]
]);


export const mapMappings: Map<string, string> = new Map([
    ["de_dust2", "Dust2"],
    ["de_inferno", "Inferno"],
    ["de_mirage", "Mirage"],
    ["de_nuke", "Nuke"],
    ["de_overpass", "Overpass"],
    ["de_vertigo", "Vertigo"],
    ["de_ancient", "Ancient"],
    ["de_anubis", "Anubis"],
]);

export const roles = ["IGL", "Entry Fragger", "Support", "AWPer", "Lurker", "Rifler", "Coach", "Anchor"];

export const currentSeason = 27;

export const currentStandingSeason = 27;

export const admins = [76561198269061841, 76561198125971713]
export const betaUsers = [
    "76561198269061841",
    "76561198125971713",
    "76561198265857122",
    "76561198258148023",
    "76561197985959978",
    "76561197986405661",
    "76561198432023000",
    "76561198081246043",
    "76561198045548888",
    "76561199069898074",
    "76561198803680855",
    "76561198984376510",
    "76561198201496594",
    "76561199122348312",
    "76561198277353799",
    "76561198280530766",
    "76561197970395883",
    "76561198344216742",
    "76561198088559740",
    "76561198905721997",
    "76561198071607216",
    "76561198128064229",
    "76561198013173057"
]
