import axios from "axios";
import { baseURL } from "../Constants";
import { FantasyTeam } from "../models/FantasyTeam";
import { FantasyStartTeam } from "../models/FantasyStartTeam";
import { Account } from "../models/Account";
import { FantasyPlayerMatch } from "../models/FantasyPlayerMatch";

const url = `${baseURL}/Fantasy`;

export const getFantasyPlayerMatchesByTeamId = async (teamId: number): Promise<FantasyPlayerMatch[]> => {
    try {
        const response = await axios.get(`${url}/getFantasyPlayerMatchesByTeamId?teamId=${teamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
};

export const createFantasyTeam = async (token: string, fantasyTeam: FantasyTeam): Promise<boolean> => {
    // Remove the incomingTeamId field from players
    const sanitizedTeam = {
        ...fantasyTeam,
        players: fantasyTeam.players.map(({ incomingTeamId, ...rest }) => rest) // Exclude incomingTeamId
    };

    const response = await axios.post(`${url}/createFantasyTeam`, sanitizedTeam, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.status === 200;
}

export const getFantasyTeams = async (steamId: string): Promise<FantasyTeam[]> => {
    try {
        const response = await axios.get(`${url}/getFantasyTeams?steamId=${steamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const checkPlayerHasFantasyTeam = async (steamId: string, season: number): Promise<boolean> => {
    const response = await fetch(`${url}/hasFantasyTeam?steamId=${steamId}&season=${season}`);
    const hasTeam = await response.json();
    return hasTeam;
};


export const getFantasySimpleTeams = async (steamId: string, season: number): Promise<FantasyTeam[]> => {
    try {
        const response = await axios.get(`${url}/getFantasyTeamsSeason?steamId=${steamId}&season=${season}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const updateFantasyTeam = async (token: string, fantasyTeam: FantasyTeam): Promise<boolean> => {
    try {
        // Remove the incomingTeamId field from players
        const sanitizedTeam = {
            ...fantasyTeam,
            players: fantasyTeam.players.map(({ incomingTeamId, ...rest }) => rest) // Exclude incomingTeamId
        };

        const response = await axios.post(`${url}/updateFantasyTeam`, sanitizedTeam, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.status === 200;
    }
    catch (error) {
        return false;
    }
}

export const deleteFantasyTeam = async (token: string, fantasyTeam: FantasyTeam): Promise<boolean> => {
    try {
        const response = await axios.delete(`${url}/deleteFantasyTeam`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: fantasyTeam
        });
        return response.status === 200;
    }
    catch (error) {
        return false;
    }
}

export const getAllTeams = async (season: number, division: string, group: string | null = null): Promise<FantasyStartTeam[]> => {
    try {
        const response = await axios.get(`${url}/getFantasyTeamsBySeasonAndDivision?season=${season}&division=${division}${group !== null ? `${group}` : ''}`);

        // Map over teams to assign incomingTeamId to each player
        const teams = response.data.map((team: FantasyStartTeam) => {
            team.players.forEach(player => {
                player.incomingTeamId = team.id; // Assign the GUID from the FantasyStartTeam to the player
            });
            return team;
        });

        return teams;
    } catch (error) {
        throw error;
    }
};

export const getFantasyTeamRanking = async (season: number, division: string, steamId: string): Promise<number> => {
    try {
        const response = await axios.get(`${url}/getFantasyTeamRanking?season=${season}&division=${division}&steamId=${steamId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};



export const getFantasyLeaderboard = async (season: number, steamId: string | null): Promise<{ [division: string]: FantasyTeam[] }> => {
    try {
        const response = await axios.get(`${url}/getFantasyLeaderboard?season=${season}&steamId=${steamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getMostPickedPlayers = async (season: number): Promise<{ account: Account, picks: number }[]> => {
    try {
        const response = await axios.get(`${url}/getPopularPicks?season=${season}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getSeasons = async (): Promise<number[]> => {
    try {
        const response = await axios.get(`${url}/getFantasySeasons`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const isCurrentSeasonLocked = async (): Promise<boolean> => {
    try {
        const response = await axios.get(`${url}/isCurrentSeasonLocked`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}