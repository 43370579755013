import React from 'react';

interface PostCardSectionProps {
    title: string;
    content: string;
}

const PostCardSection: React.FC<PostCardSectionProps> = ({ title, content }) => {
    return (
        <div 
            className="card mb-3" 
            style={{ 
                width: "550px", // Fixed width
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
        >
            <div className="card-body" style={{ overflow: "hidden" }}>
                <h6 className="card-subtitle mb-2 text-muted">{title}</h6>
                <p className="card-text">{content}</p>
            </div>
        </div>
    );
};

export default PostCardSection;
