import axios from "axios";
import { baseURL } from "../Constants";
import { LookingForPlayers } from "../models/LookingForPlayers";

const url = `${baseURL}/LookingForPlayers`;

export const fetchLookingForPlayersPosts = async (): Promise<LookingForPlayers[]> => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const createLookingForPlayersPost = async (post: LookingForPlayers): Promise<LookingForPlayers> => {
    try {
        const response = await axios.post(`${url}`, post);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateLookingForPlayersPost = async (post: LookingForPlayers): Promise<LookingForPlayers> => {
    try {
        const response = await axios.put(`${url}/${post.id}`, post);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const deleteLookingForPlayersPost = async (id: number): Promise<boolean> => {
    try {
        const response = await axios.delete(`${url}/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}
