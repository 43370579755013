import { Link } from "react-router-dom";
import { UpcomingMatch } from "../models/UpcomingMatch";
import { FC } from "react";
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage

interface UpcomingMatchTableProps {
    matchesForDate: UpcomingMatch[];
}

const UpcomingMatchTable: FC<UpcomingMatchTableProps> = ({ matchesForDate }) => {
    const [isDark] = useLocalStorage<boolean>("isDark", false); // Retrieve isDark mode

    return (
        <div className={`text-center ${isDark ? 'text-light' : ''}`}>
            <table
                className={`table table-striped ${isDark ? 'table-dark' : ''}`}
                style={
                    isDark
                        ? {
                            border: '1px solid #111',
                            borderCollapse: 'collapse' as 'collapse', // Type assertion here
                        }
                        : undefined
                }
            >
                <thead>
                    <tr>
                        <th style={{ width: '35%' }}>Hold 1</th>
                        <th style={{ width: '30%' }}>VS</th> {/* Adjust the width for centering */}
                        <th style={{ width: '35%' }}>Hold 2</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        matchesForDate.map((match, index) => (
                            <tr key={index} style={{ height: '80px' }}> {/* Match the row height */}
                                <td className="align-middle">
                                    <Link to={`/team/${match.team1.teamId}`} className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'} d-flex flex-column align-items-center`}>
                                        <img
                                            src={match.team1.teamLogo}
                                            alt={match.team1.teamName}
                                            style={{ width: 40, height: 40, objectFit: 'contain' }} // Match image sizing
                                            loading="lazy" />
                                        <span className="mt-1 text-truncate" style={{ maxWidth: '100%' }}>{match.team1.teamName}</span>
                                    </Link>
                                </td>
                                <td className="align-middle text-center" style={{ fontSize: '1.5rem' }}> {/* Center the VS text */}
                                    <div>
                                        <span>{new Date(match.match_time).toLocaleTimeString('da-DK', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })}</span>
                                    </div>
                                </td>
                                <td className="align-middle">
                                    <Link to={`/team/${match.team2.teamId}`} className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'} d-flex flex-column align-items-center`}>
                                        <img
                                            src={match.team2.teamLogo}
                                            alt={match.team2.teamName}
                                            style={{ width: 40, height: 40, objectFit: 'contain' }} // Match image sizing
                                            loading="lazy" />
                                        <span className="mt-1 text-truncate" style={{ maxWidth: '100%' }}>{match.team2.teamName}</span>
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default UpcomingMatchTable;
